















































































import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  toRefs,
  computed,
  watch,
  PropType,
  onBeforeUnmount,
  useContext
} from '@nuxtjs/composition-api'
import CCalendar from '~/components/shared/configurable/date/CCalendar.vue'
import { parseDate, format, getDateLocale } from '~/utils/date'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { Ref } from '@vue/composition-api'
import { useFormComponent } from '~/compositions/form-component'
import { DropdownPosition } from '~/models/app/dropdown'

export default defineComponent({
  model: {
    prop: 'date',
    event: 'input'
  },
  props: {
    date: {
      type: [Date, String, Object],
      default: () => null
    },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    range: { type: Boolean, default: false },
    resetButton: { type: Boolean, default: false },
    resetButtonLabel: { type: String, default: null },
    applyButton: { type: Boolean, default: false },
    locale: { type: [String, Object], default: 'el' },
    disabledDates: { type: Array, default: () => [] },
    inputDateFormat: { type: String, default: 'dd/MM/yyyy' },
    textDateFormat: { type: String, default: 'dd/MM/yyyy' },
    max: { type: [Date, String], default: null },
    min: { type: [Date, String], default: null },
    valueAsDate: { type: Boolean, default: null },
    availableDates: { type: Array, default: () => [] },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md'
    },
    closeOnSelect: { type: Boolean, default: true },
    state: { type: Boolean, default: null },
    withoutIcon: { type: Boolean, default: false },
    skidding: { type: Number, default: null },
    inputClass: { type: [String, Array, Object], default: null },
    placement: {
      type: String as PropType<DropdownPosition>,
      default: 'bottom-start'
    },
    warning: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CCalendar
  },
  setup(props, { emit }) {
    const {
      date,
      size,
      closeOnSelect,
      disabled,
      inputDateFormat,
      range,
      valueAsDate,
      textDateFormat,
      locale: localeProp,
      state,
      warning
    } = toRefs(props)
    const internalDate: Ref<Date | string | object> = ref()
    const isVCalendarMounted = ref(false)
    internalDate.value = normalizedDate(date.value)
    const { app } = useContext()
    const isDatepickerVisible = ref(false)
    const dDown = ref(null)

    const { controlClasses } = useFormComponent(
      ref(''),
      ref(state.value === false),
      ref(state.value === true),
      warning,
      'input'
    )

    watch(date, (newDate, oldDate) => {
      if (newDate === oldDate) {
        return
      }
      internalDate.value = normalizedDate(newDate)
    })

    const datepickerTemplateRef = ref()

    onMounted(() => {
      document.addEventListener('mousedown', handleClick)
    })
    onBeforeUnmount(() => {
      document.removeEventListener('mousedown', handleClick)
    })

    const formatedInternalDate = computed(() => {
      return getFormatedDate(inputDateFormat.value)
    })

    const formatedTextDate = computed(() => {
      return getFormatedDate(textDateFormat.value)
    })

    function getFormatedDate(formatString: string) {
      if (internalDate.value && range.value) {
        const { start, end } = internalDate.value as { end: Date; start: Date }

        if ((!start || !end) && typeof internalDate.value === 'object') {
          return {}
        }
        return `${formatDate(start, formatString)} - ${formatDate(
          end,
          formatString
        )}`
      }
      return formatDate(internalDate.value as Date, formatString)
    }

    function formatDate(
      date: Date,
      formatString: string = inputDateFormat.value
    ) {
      return format(date, formatString, { locale: app.i18n.locale })
    }

    function handleDateInput(date: Date) {
      if (isDatepickerVisible.value && closeOnSelect.value) {
        dDown.value.hide()
        isDatepickerVisible.value = false
      }
      if (valueAsDate.value) {
        emit('input', date)
      } else {
        emit('input', formatedInternalDate.value)
      }
    }
    const handleInputClick = async () => {
      if (!disabled.value) {
        dDown.value.show()
        await nextTick()
      }
    }

    function handleClick(e: MouseEvent) {
      const target = e.target as HTMLElement
      const parent = target?.parentNode as HTMLElement
      const isResetButton =
        parent?.dataset?.attribute === 'reset' ||
        target.dataset?.attribute === 'reset'
      if (disabled.value || isResetButton) {
        return
      }
      const isClickDatepicker = !!(e.target as HTMLElement).closest(
        '.c-datepicker'
      )
      if (
        (isDatepickerVisible.value &&
          !closeOnSelect.value &&
          !isClickDatepicker) ||
        (isDatepickerVisible.value && !isClickDatepicker)
      ) {
        isDatepickerVisible.value = false
        dDown.value.hide()
      }
    }
    function handleHidden() {
      emit('hidden')
    }
    function handleShown() {
      isDatepickerVisible.value = true
      emit('shown')
    }

    const sizeClasses = computed(() => {
      switch (size.value) {
        case 'sm': {
          return [
            'tw-h-[32px]',
            'tw-min-h-[32px]',
            'tw-text-base',
            'tw-rounded-md'
          ]
        }
        case 'lg': {
          return [
            'tw-h-[48px]',
            'tw-min-h-[48px]',
            'tw-text-lg',
            'tw-rounded-xl'
          ]
        }
        case 'md':
        default: {
          return [
            'tw-h-[40px]',
            'tw-min-h-[40px]',
            'tw-text-base',
            'tw-rounded-lg'
          ]
        }
      }
    })

    const computedClasses = computed(() => {
      const classes = []
      if (disabled.value) {
        classes.push(
          ...['tw-bg-grey-300', 'tw-cursor-not-allowed', 'pointer-events-none']
        )
      } else {
        classes.push(...['tw-bg-white', 'tw-cursor-pointer'])
      }

      if (isDatepickerVisible.value) {
        if (state.value) {
          classes.push('tw-ring-green-500', '!tw-border-green-500')
        } else if (state.value === false) {
          classes.push('tw-ring-red-500', '!tw-border-red-500')
        } else if (warning.value) {
          classes.push('tw-ring-amber-500', '!tw-border-amber-500')
        } else {
          classes.push('tw-ring-blue-500', '!tw-border-blue-500')
        }
        classes.push('tw-ring-1')
      }

      return [...sizeClasses.value, ...classes, ...controlClasses.value]
    })

    function handlePickerShow() {
      isVCalendarMounted.value = true
    }

    function handleClickReset() {
      internalDate.value = null
      emit('input', null)
    }

    const nothingSelected = computed(
      () =>
        !internalDate.value ||
        (Array.isArray(internalDate.value) && internalDate.value.length === 0)
    )

    function normalizedDate(dt: string | Date | object) {
      if (typeof dt === 'string' && dt.match(/ - /)) {
        const locale = getDateLocale(localeProp)
        const [start, end] = dt.replace(/ /g, '').split('-')
        return {
          start: parseDate(start, inputDateFormat.value, locale),
          end: parseDate(end, inputDateFormat.value, locale)
        }
      } else if (typeof dt === 'string') {
        return parseDate(dt, inputDateFormat.value, getDateLocale(localeProp))
      } else if (
        (dt as any) instanceof Date ||
        (dt && typeof dt === 'object')
      ) {
        return dt
      }
      return null
    }

    return {
      datepickerTemplateRef,
      internalDate,
      handleDateInput,
      handleInputClick,
      isDatepickerVisible,
      dDown,
      handleShown,
      sizeClasses,
      handleClick,
      handleHidden,
      formatedTextDate,
      calendarIcon: faCalendar,
      handlePickerShow,
      isVCalendarMounted,
      handleClickReset,
      controlClasses,
      computedClasses,
      nothingSelected
    }
  }
})
