



































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs,
  watch
} from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import {
  QUICK_SEARCH_NS,
  QuickSearchState
} from '~/store/modules/shared/quickSearch/state'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import CCountTo from '~/components/shared/configurable/CCountTo.vue'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Facet from '~/components/shared/quick-search/facets/Facet.vue'
import { Facet as FacetType } from '~/models/quick-search/types'
import { capitalizeSentences } from '~/utils/string'
import { formatNumber } from '~/utils/number'
import { ButtonVariant } from '~/models/app/button'

export default defineComponent({
  props: {
    whiteBackground: {
      type: Boolean,
      default: false
    },
    btnVariant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary'
    },
    size: {
      type: String as PropType<'md' | 'lg'>,
      default: 'md'
    },
    flex: {
      type: Boolean,
      require: false,
      default: false
    },
    placeholderCategories: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => {
        return ['offertype', 'category', 'location', 'btn']
      }
    },
    placeholderBgClass: {
      type: String,
      required: false,
      default: 'tw-bg-white'
    },
    countAnimation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: { CCountTo, Facet },
  setup(props) {
    const { whiteBackground } = toRefs(props)
    const { size } = toRefs(props)
    const { state } = useNamespacedStore<QuickSearchState>(QUICK_SEARCH_NS)
    const { state: searchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )

    const previousTotal = ref(0)

    const isQuickSearch = computed(() => searchState.isQuickSearch)
    const facets = computed(() => state.facets)
    const targets = computed(() => state.targets)
    const config = computed(() => state.config)

    const total = computed(() => state.stats.total)
    watch(total, (_newTotal, oldTotal) => (previousTotal.value = oldTotal))

    const icons = {
      search: faSearch
    }

    function getFacetLgCols(facetName: string) {
      return (
        (config.value.facetsCols && config.value.facetsCols[facetName]) || 3
      )
    }

    function hasFlexGrow(facetName: string) {
      return (
        (config.value.flexGrow && config.value.flexGrow[facetName]) || false
      )
    }

    function facetNameIsHideable(facetName: string): boolean {
      return Boolean(
        config.value.hideOnMobileWhenNoSearchables &&
          config.value.hideOnMobileWhenNoSearchables[facetName]
      )
    }

    function hideOnMobileWhenNoSearchables(facet: FacetType): boolean {
      const isHideable = facetNameIsHideable(facet.name)

      const shouldHide = Boolean(isHideable && !facet.searchables?.length)

      if (isHideable) {
        this.$emit('mobile-visibility-status', {
          name: facet.name,
          hidden: shouldHide
        })
      }

      return shouldHide
    }

    const containerClasses = computed(() => {
      function backgroundClasses() {
        if (whiteBackground.value) {
          return ['tw-bg-white']
        }
        return ['tw-bg-white/30 tw-backdrop-blur']
      }

      function paddingClasses() {
        if (size.value === 'lg') {
          return ['tw-p-5']
        }
        return ['tw-p-2']
      }

      return [...backgroundClasses(), ...paddingClasses()]
    })

    const targetUrl = computed(() => targets.value.search?.seoUrl || null)
    const showButtonDisabled = computed(() =>
      !isQuickSearch.value ? total.value === 0 || !targetUrl : false
    )

    return {
      facets,
      targets,
      previousTotal,
      total,
      icons,
      getFacetLgCols,
      hasFlexGrow,
      hideOnMobileWhenNoSearchables,
      facetNameIsHideable,
      containerClasses,
      showButtonDisabled,
      targetUrl,
      capitalizeSentences,
      formatNumber,
      isQuickSearch
    }
  }
})
