
import {
  defineComponent,
  h,
  PropType,
  ref,
  toRefs,
  useRoute,
  useRouter,
  watch
} from '@nuxtjs/composition-api'
import { VNode, VNodeChildren } from 'vue'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import { useTabRef } from '~/compositions/tab/ref'
import { useTabScroll } from '~/compositions/tab/scroll'
import { useTabs } from '~/compositions/tab'
import { useTabsClasses } from '~/compositions/tab/class'
import { noop } from '~/utils/function'
import { TabVariant } from '~/models/app/tab'

export default defineComponent({
  props: {
    pills: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    },
    vertical: {
      type: Boolean,
      default: false
    },
    nav: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    // Can be used alongside nav, when the tabs navigate to a query instead of a page/path
    // The route match checks are performed on the full path instead of the path
    queryNav: {
      type: Boolean,
      default: false
    },
    // Can be used alongside nav, when you don't care about the query of the routes in the tabs but only the names
    // it's like the opposite of the queryNav prop above
    nameNav: {
      type: Boolean,
      default: false
    },
    condensedMobile: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String as PropType<TabVariant>,
      default: 'primary'
    },
    contentClass: {
      type: String,
      default: '',
      required: false
    },
    navClass: {
      type: String,
      default: '',
      required: false
    },
    tabClass: {
      type: String,
      default: '',
      required: false
    },
    activeTabClass: {
      type: String,
      default: '',
      required: false
    }
  },
  setup(props, { slots, emit }: any) {
    const {
      pills,
      value,
      nav,
      vertical,
      queryNav,
      nameNav,
      condensedMobile,
      variant,
      fullWidth,
      contentClass,
      navClass,
      tabClass,
      activeTabClass
    } = toRefs(props)

    const route = useRoute()
    const router = useRouter()

    const selectedTabIndex = ref(value.value)
    watch(value, newValue => selectTab(newValue))

    const { tabIsActive, getActiveTab } = useTabs(selectedTabIndex)
    const { getTabRefName, getNavRefName } = useTabRef()
    const { scrollToTabIfOverflown } = useTabScroll(
      selectedTabIndex,
      vertical.value
    )
    const {
      getTabClasses,
      getTabsContainerClasses,
      getContainerClass,
      getNavClasses,
      getContentClasses
    } = useTabsClasses(pills.value, vertical.value, condensedMobile.value)

    function selectTab(index: number, _e: any) {
      selectedTabIndex.value = index
      if (!vertical.value) {
        scrollToTabIfOverflown(index)
      }
      emit('input', index)
    }

    function getTabTitleContent(node: VNode): VNodeChildren {
      const titleSlot = node.data?.scopedSlots?.title
      const titleProp = node.componentOptions?.propsData?.title
      if (titleSlot) {
        return [titleSlot({})]
      }
      if (titleProp) {
        return titleProp
      }
      return []
    }

    function renderContent(tabs: VNode[]): VNodeChildren {
      if (nav.value) {
        // Do not create the tab content in case of nav tabs
        return []
      }
      if (!slots || !slots.default) {
        return []
      }
      const activeTab = getActiveTab(tabs)

      const activeTabChildren = activeTab?.componentOptions?.children
      if (!activeTabChildren?.length) {
        return []
      }
      return [
        h(
          'div',
          { class: [...getContentClasses(), contentClass.value] },
          activeTabChildren || []
        )
      ]
    }

    function renderTabs(tabs: VNode[]): VNodeChildren {
      function createTabElement(node: VNode, index: number) {
        const tabProps = node.componentOptions?.propsData as any
        const disabled = tabProps.disabled === true || tabProps.disabled === ''
        function getTag() {
          return nav.value ? CLink : 'button'
        }
        function getProps() {
          if (nav.value) {
            return {
              to: tabProps.to,
              href: tabProps.href,
              target: tabProps.target,
              useExternalLinkMediator: tabProps.useExternalLinkMediator,
              disabled
            }
          }
          return {}
        }
        function getListeners() {
          if (disabled) {
            return {
              click: noop
            }
          }
          return {
            click: (e: MouseEvent) => {
              selectTab(index, e)
              const clickListener = node?.componentOptions?.listeners?.click
              if (clickListener) {
                clickListener(e)
              }
            }
          }
        }

        const extraClasses = []
        if (tabClass.value) {
          extraClasses.push(tabClass.value)
        }

        if (activeTabClass.value && tabIsActive(index)) {
          extraClasses.push(activeTabClass.value)
        }

        return h(
          getTag(),
          {
            attrs: {
              disabled: nav.value ? false : disabled,
              ...node.data?.attrs
            },
            props: getProps(),
            staticClass: 'tw-whitespace-nowrap tab-button',
            class: [
              ...getTabClasses(
                tabIsActive(index),
                disabled,
                condensedMobile.value,
                variant.value,
                fullWidth.value
              ),
              ...extraClasses
            ],
            ref: getTabRefName(index),
            on: getListeners()
          },
          [getTabTitleContent(node)]
        )
      }

      return tabs.map((node: VNode, index: number) =>
        createTabElement(node, index)
      )
    }

    function setSelectedTabIndexByPath(tabs: VNode[]) {
      tabs.forEach((tab, index) => {
        const props: any = tab.componentOptions?.propsData
        if (!props) {
          return
        }
        const { href, to } = props

        if (nameNav.value && route.value.name === to.name) {
          selectedTabIndex.value = index
          return
        }

        let toAsPath
        if (typeof to === 'string') {
          toAsPath = to
        } else if (typeof href === 'string') {
          toAsPath = href
        } else {
          const href = router.resolve(to)?.href
          if (!href.endsWith('/')) {
            toAsPath = href + '/'
          } else {
            toAsPath = href
          }
        }
        let pathToCompare = queryNav.value
          ? route.value.fullPath
          : route.value.path

        if (toAsPath?.endsWith('/') && !pathToCompare.endsWith('/')) {
          pathToCompare += '/'
        }

        if (
          toAsPath &&
          (pathToCompare.startsWith(href) || pathToCompare.startsWith(toAsPath))
        ) {
          selectedTabIndex.value = index
        }
      })
    }

    return () => {
      const tabs = ((slots?.default && slots.default()) || []).filter(
        (node: VNode) => node.componentOptions?.tag === 'CTab'
      )
      if (nav.value) {
        setSelectedTabIndexByPath(tabs)
      }

      return h('div', { staticClass: 'tw-flex', class: getContainerClass() }, [
        h(
          'div',
          {
            class: getTabsContainerClasses()
          },
          [
            h(
              'nav',
              {
                staticClass:
                  'tw--mb-px tw-flex tw-overflow-auto tw-flex-nowrap tw-hide-scrollbar tw-relative',
                ref: getNavRefName(),
                class: [...getNavClasses(), navClass.value]
              },
              renderTabs(tabs)
            )
          ]
        ),
        renderContent(tabs)
      ])
    }
  }
})
